import React from 'react';
import './Gallery.css';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer'

const Gallery = () => {
  return (
    <>
      <div className="banner">
        <Navbar />
        <div className="hero__content">
          <div className="container">
            <div className="row g-3 justify-content-center">
              <div className="col-lg-10 text-center">
                <h1 className="hero__content-title text-capitalize t-text-white">
                  Gallery
                </h1>
                <ul className="list list--row breadcrumbs justify-content-center">
                  <li className="list--row__item breadcrumbs__item">
                    <a
                      href="/"
                      className="
                    t-link
                    breadcrumbs__link
                    text-uppercase
                    t-text-white
                    t-link--primary
                  "
                    >
                      home
                    </a>
                  </li>
                  <li className="list--row__item breadcrumbs__item">
                    <a
                      href="/training"
                      className="
                    t-link
                    breadcrumbs__link
                    text-uppercase
                    t-text-white
                    t-link--primary
                  "
                    >
                      trainer
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>


      {/* Gallery Section  */}

      {/* <section className="py-5">
        <div className="section__head">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-8 col-xl-8">
                <div className="text-center">
                  <div className="d-flex align-items-center justify-content-center">
                    <div className="vf-custom-icon me-2 flex-shrink-0">
                      <img
                        src="assets/img/icon-plane.png"
                        alt="viserfly"
                        className="img-fluid"
                      />
                    </div>
                    <p className="mb-0 text-capitalize text--primary xxl-text">
                      gallery
                    </p>
                  </div>
                  <h2 className="text-capitalize"> <span style={{ color: "#1C335D" }}>Our Photo</span>Gallery</h2>
                  <p className="t-short-para mx-auto mb-0">
                    Welcome to our captivating photo gallery, where each image tells a story of passion, dedication, and the pursuit of excellence at Alroz Aviation Institute. Take a visual journey through the lens of our institute's vibrant life and witness the essence of aviation education.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="grid-container">
          <div>
            <img
              className="grid-item grid-item-1"
              src="\assets\img\gal18.webp"
              alt=""
            />
          </div>
          <div>
            <img
              className="grid-item grid-item-2"
              src="\assets\img\gal17.webp"
              alt=""
            />
          </div>
          <div>
            <img
              className="grid-item grid-item-3"
              src="\assets\img\gal15.webp"
              alt=""
            />
          </div>
          <div>
            <img
              className="grid-item grid-item-4"
              src="\assets\img\gal14.webp"
              alt=""
            />
          </div>
          <div>
            <img
              className="grid-item grid-item-5"
              src="\assets\img\gal13.webp"
              alt=""
            />
          </div>
          <div>
            <img
              className="grid-item grid-item-6"
              src="\assets\img\gal12.webp"
              alt=""
            />
          </div>
          <div>
            <img
              className="grid-item grid-item-7"
              src="\assets\img\gal11.webp"
              alt=""
            />
          </div>
          <div>
            <img
              className="grid-item grid-item-8"
              src="\assets\img\gal4.webp"
              alt=""
            />
          </div>
          <div>
            <img
              className="grid-item grid-item-9"
              src="\assets\img\gal9.webp"
              alt=""
            />
          </div>
          <div>
            <img
              className="grid-item grid-item-10"
              src="\assets\img\gal8.webp"
              alt=""
            />
          </div>
          <div>
            <img
              className="grid-item grid-item-1"
              src="\assets\img\gal7.webp"
              alt=""
            />
          </div>
          <div>
            <img
              className="grid-item grid-item-2"
              src="\assets\img\gal6.webp"
              alt=""
            />
          </div>
          <div>
            <img
              className="grid-item grid-item-3"
              src="\assets\img\gal5.webp"
              alt=""
            />
          </div>
          <div>
            <img
              className="grid-item grid-item-4"
              src="\assets\img\gal4.webp"
              alt=""
            />
          </div>
          <div>
            <img
              className="grid-item grid-item-5"
              src="\assets\img\gal3.webp"
              alt=""
            />
          </div>
          <div>
            <img
              className="grid-item grid-item-6"
              src="\assets\img\gal2.webp"
              alt=""
            />
          </div>
          <div>
            <img
              className="grid-item grid-item-7"
              src="\assets\img\gal1.webp"
              alt=""
            />
          </div>
          <div>
            <img
              className="grid-item grid-item-8"
              src="\assets\img\gal19.webp"
              alt=""
            />
          </div>
          <div>
            <img
              className="grid-item grid-item-9"
              src="\assets\img\gal20.webp"
              alt=""
            />
          </div>
          <div>
            <img
              className="grid-item grid-item-10"
              src="\assets\img\gal21.webp"
              alt=""
            />
          </div>
          <div>
            <img
              className="grid-item grid-item-1"
              src="\assets\img\gal22.webp"
              alt=""
            />
          </div>
          <div>
            <img
              className="grid-item grid-item-2"
              src="\assets\img\gal23.webp"
              alt=""
            />
          </div>
          <div>
            <img
              className="grid-item grid-item-3"
              src="\assets\img\gal24.webp"
              alt=""
            />
          </div>
          <div>
            <img
              className="grid-item grid-item-4"
              src="\assets\img\gal25.webp"
              alt=""
            />
          </div>
          <div>
            <img
              className="grid-item grid-item-5"
              src="\assets\img\gal26.webp"
              alt=""
            />
          </div>
          <div>
            <img
              className="grid-item grid-item-6"
              src="\assets\img\gal27.webp"
              alt=""
            />
          </div>
          <div>
            <img
              className="grid-item grid-item-7"
              src="\assets\img\gal28.webp"
              alt=""
            />
          </div>
          <div>
            <img
              className="grid-item grid-item-8"
              src="\assets\img\gal29.webp"
              alt=""
            />
          </div>
          <div>
            <img
              className="grid-item grid-item-9"
              src="\assets\img\gal30.webp"
              alt=""
            />
          </div>
          <div>
            <img
              className="grid-item grid-item-10"
              src="\assets\img\gal31.webp"
              alt=""
            />
          </div>

          <div>
            <img
              className="grid-item grid-item-1"
              src="\assets\img\gal32.webp"
              alt=""
            />
          </div>
          <div>
            <img
              className="grid-item grid-item-2"
              src="\assets\img\gal44.webp"
              alt=""
            />
          </div>
          <div>
            <img
              className="grid-item grid-item-3"
              src="\assets\img\gal34.webp"
              alt=""
            />
          </div>
          <div>
            <img
              className="grid-item grid-item-4"
              src="\assets\img\gal35.webp"
              alt=""
            />
          </div>
          <div>
            <img
              className="grid-item grid-item-5"
              src="\assets\img\gal36.webp"
              alt=""
            />
          </div>
          <div>
            <img
              className="grid-item grid-item-6"
              src="\assets\img\gal37.webp"
              alt=""
            />
          </div>
          <div>
            <img
              className="grid-item grid-item-7"
              src="\assets\img\gal38.webp"
              alt=""
            />
          </div>
          <div>
            <img
              className="grid-item grid-item-8"
              src="\assets\img\gal39.webp"
              alt=""
            />
          </div>
          <div>
            <img
              className="grid-item grid-item-9"
              src="\assets\img\gal40.webp"
              alt=""
            />
          </div>
          <div>
            <img
              className="grid-item grid-item-10"
              src="\assets\img\gal41.webp"
              alt=""
            />
          </div>
          <div>
            <img
              className="grid-item grid-item-1"
              src="\assets\img\gal42.webp"
              alt=""
            />
          </div>
          <div>
            <img
              className="grid-item grid-item-2"
              src="\assets\img\gal43.webp"
              alt=""
            />
          </div>
        </div>
      </section> */}

      {/* Gallery Section End */}

      {/* New Gallery Start */}
      <section className="py-5">
        <div className="container">
          <div className="row justify-content-center g-4">
            <div className="col-12">
              <div className="row filter-container g-0">
                <div
                  className="col-md-6 col-lg-4 filtr-item"
                  data-category="web"
                  data-sort="value"
                >
                <div className="gallery-photo">
                    <img
                      src="/assets/img/gal37.webp"
                      alt="viserfly"
                      className="gallery-photo__img"
                    />
                    <div className="gallery-photo__content">
                      <ul className="list list--row">
                        <li className="list--row__item">
                          <a
                            href="/assets/img/gal37.webp"
                            className="t-link gallery-photo-magnify gallery-photo__icon btn btn--sqr"
                          >
                            <i className="bx bx-show" />
                          </a>
                        </li>
                      </ul>
                    </div>
                </div>
                </div>
                <div
                  className="col-md-6 col-lg-4 filtr-item"
                  data-category="branding"
                  data-sort="value"
                >
                  <div className="gallery-photo">
                    <img
                      src="\assets\img\gal8.webp"
                      alt="viserfly"
                      className="gallery-photo__img"
                    />
                    <div className="gallery-photo__content">
                      <ul className="list list--row">
                        <li className="list--row__item">
                          <a
                            href="\assets\img\gal8.webp"
                            className="t-link gallery-photo-magnify gallery-photo__icon btn btn--sqr"
                          >
                            <i className="bx bx-show" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-6 col-lg-4 filtr-item"
                  data-category="web"
                  data-sort="value"
                >
                  <div className="gallery-photo">
                    <img
                      src="\assets\img\gal9.webp"
                      alt="viserfly"
                      className="gallery-photo__img"
                    />
                    <div className="gallery-photo__content">
                      <ul className="list list--row">
                        <li className="list--row__item">
                          <a
                            href="\assets\img\gal9.webp"
                            className="t-link gallery-photo-magnify gallery-photo__icon btn btn--sqr"
                          >
                            <i className="bx bx-show" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-6 col-lg-4 filtr-item"
                  data-category="branding"
                  data-sort="value"
                >
                  <div className="gallery-photo">
                    <img
                      src="\assets\img\gal10.webp"
                      alt="viserfly"
                      className="gallery-photo__img"
                    />
                    <div className="gallery-photo__content">
                      <ul className="list list--row">
                        <li className="list--row__item">
                          <a
                            href="\assets\img\gal10.webp"
                            className="t-link gallery-photo-magnify gallery-photo__icon btn btn--sqr"
                          >
                            <i className="bx bx-show" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-6 col-lg-4 filtr-item"
                  data-category="seo"
                  data-sort="value"
                >
                  <div className="gallery-photo">
                    <img
                      src="\assets\img\gal11.webp"
                      alt="viserfly"
                      className="gallery-photo__img"
                    />
                    <div className="gallery-photo__content">
                      <ul className="list list--row">
                        <li className="list--row__item">
                          <a
                            href="\assets\img\gal11.webp"
                            className="t-link gallery-photo-magnify gallery-photo__icon btn btn--sqr"
                          >
                            <i className="bx bx-show" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-6 col-lg-4 filtr-item"
                  data-category="branding"
                  data-sort="value"
                >
                  <div className="gallery-photo">
                    <img
                      src="\assets\img\gal12.webp"
                      alt="viserfly"
                      className="gallery-photo__img img-fluid"
                    />
                    <div className="gallery-photo__content">
                      <ul className="list list--row">
                        <li className="list--row__item">
                          <a
                            href="\assets\img\gal12.webp"
                            className="t-link gallery-photo-magnify gallery-photo__icon btn btn--sqr"
                          >
                            <i className="bx bx-show" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <div className="col-12">
              <div className="row filter-container g-0">
              <div
                  className="col-md-6 col-lg-4 filtr-item"
                  data-category="web"
                  data-sort="value"
                >
                  <div className="gallery-photo">
                    <img
                      src="\assets\img\gal1.webp"
                      alt="viserfly"
                      className="gallery-photo__img"
                    />
                    <div className="gallery-photo__content">
                      <ul className="list list--row">
                        <li className="list--row__item">
                          <a
                            href="\assets\img\gal1.webp"
                            className="t-link gallery-photo-magnify gallery-photo__icon btn btn--sqr"
                          >
                            <i className="bx bx-show" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-6 col-lg-4 filtr-item"
                  data-category="branding"
                  data-sort="value"
                >
                  <div className="gallery-photo">
                    <img
                      src="\assets\img\gal2.webp"
                      alt="viserfly"
                      className="gallery-photo__img"
                    />
                    <div className="gallery-photo__content">
                      <ul className="list list--row">
                        <li className="list--row__item">
                          <a
                            href="\assets\img\gal2.webp"
                            className="t-link gallery-photo-magnify gallery-photo__icon btn btn--sqr"
                          >
                            <i className="bx bx-show" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-6 col-lg-4 filtr-item"
                  data-category="web"
                  data-sort="value"
                >
                  <div className="gallery-photo">
                    <img
                      src="\assets\img\gal3.webp"
                      alt="viserfly"
                      className="gallery-photo__img"
                    />
                    <div className="gallery-photo__content">
                      <ul className="list list--row">
                        <li className="list--row__item">
                          <a
                            href="\assets\img\gal3.webp"
                            className="t-link gallery-photo-magnify gallery-photo__icon btn btn--sqr"
                          >
                            <i className="bx bx-show" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-6 col-lg-4 filtr-item"
                  data-category="branding"
                  data-sort="value"
                >
                  <div className="gallery-photo">
                    <img
                      src="\assets\img\gal4.webp"
                      alt="viserfly"
                      className="gallery-photo__img"
                    />
                    <div className="gallery-photo__content">
                      <ul className="list list--row">
                        <li className="list--row__item">
                          <a
                            href="\assets\img\gal4.webp"
                            className="t-link gallery-photo-magnify gallery-photo__icon btn btn--sqr"
                          >
                            <i className="bx bx-show" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-6 col-lg-4 filtr-item"
                  data-category="seo"
                  data-sort="value"
                >
                  <div className="gallery-photo">
                    <img
                      src="\assets\img\gal5.webp"
                      alt="viserfly"
                      className="gallery-photo__img"
                    />
                    <div className="gallery-photo__content">
                      <ul className="list list--row">
                        <li className="list--row__item">
                          <a
                            href="\assets\img\gal5.webp"
                            className="t-link gallery-photo-magnify gallery-photo__icon btn btn--sqr"
                          >
                            <i className="bx bx-show" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-6 col-lg-4 filtr-item"
                  data-category="branding"
                  data-sort="value"
                >
                  <div className="gallery-photo">
                    <img
                      src="\assets\img\gal7.webp"
                      alt="viserfly"
                      className="gallery-photo__img img-fluid"
                    />
                    <div className="gallery-photo__content">
                      <ul className="list list--row">
                        <li className="list--row__item">
                          <a
                            href="\assets\img\gal7.webp"
                            className="t-link gallery-photo-magnify gallery-photo__icon btn btn--sqr"
                          >
                            <i className="bx bx-show" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <div className="col-12">
              <div className="row filter-container g-0">
                <div
                  className="col-md-6 col-lg-4 filtr-item"
                  data-category="web"
                  data-sort="value"
                >
                  <div className="gallery-photo">
                    <img
                      src="\assets\img\gal18.webp"
                      alt="viserfly"
                      className="gallery-photo__img"
                    />
                    <div className="gallery-photo__content">
                      <ul className="list list--row">
                        <li className="list--row__item">
                          <a
                            href="\assets\img\gal18.webp"
                            className="t-link gallery-photo-magnify gallery-photo__icon btn btn--sqr"
                          >
                            <i className="bx bx-show" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-6 col-lg-4 filtr-item"
                  data-category="branding"
                  data-sort="value"
                >
                  <div className="gallery-photo">
                    <img
                      src="\assets\img\gal19.webp"
                      alt="viserfly"
                      className="gallery-photo__img"
                    />
                    <div className="gallery-photo__content">
                      <ul className="list list--row">
                        <li className="list--row__item">
                          <a
                            href="\assets\img\gal19.webp"
                            className="t-link gallery-photo-magnify gallery-photo__icon btn btn--sqr"
                          >
                            <i className="bx bx-show" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-6 col-lg-4 filtr-item"
                  data-category="web"
                  data-sort="value"
                >
                  <div className="gallery-photo">
                    <img
                      src="\assets\img\gal20.webp"
                      alt="viserfly"
                      className="gallery-photo__img"
                    />
                    <div className="gallery-photo__content">
                      <ul className="list list--row">
                        <li className="list--row__item">
                          <a
                            href="\assets\img\gal20.webp"
                            className="t-link gallery-photo-magnify gallery-photo__icon btn btn--sqr"
                          >
                            <i className="bx bx-show" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-6 col-lg-4 filtr-item"
                  data-category="branding"
                  data-sort="value"
                >
                  <div className="gallery-photo">
                    <img
                      src="\assets\img\gal21.webp"
                      alt="viserfly"
                      className="gallery-photo__img"
                    />
                    <div className="gallery-photo__content">
                      <ul className="list list--row">
                        <li className="list--row__item">
                          <a
                            href="\assets\img\gal21.webp"
                            className="t-link gallery-photo-magnify gallery-photo__icon btn btn--sqr"
                          >
                            <i className="bx bx-show" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-6 col-lg-4 filtr-item"
                  data-category="seo"
                  data-sort="value"
                >
                  <div className="gallery-photo">
                    <img
                      src="\assets\img\gal22.webp"
                      alt="viserfly"
                      className="gallery-photo__img"
                    />
                    <div className="gallery-photo__content">
                      <ul className="list list--row">
                        <li className="list--row__item">
                          <a
                            href="\assets\img\gal22.webp"
                            className="t-link gallery-photo-magnify gallery-photo__icon btn btn--sqr"
                          >
                            <i className="bx bx-show" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-6 col-lg-4 filtr-item"
                  data-category="branding"
                  data-sort="value"
                >
                  <div className="gallery-photo">
                    <img
                      src="\assets\img\gal12.webp"
                      alt="viserfly"
                      className="gallery-photo__img img-fluid"
                    />
                    <div className="gallery-photo__content">
                      <ul className="list list--row">
                        <li className="list--row__item">
                          <a
                            href="\assets\img\gal12.webp"
                            className="t-link gallery-photo-magnify gallery-photo__icon btn btn--sqr"
                          >
                            <i className="bx bx-show" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <div className="col-12">
              <div className="row filter-container g-0">
                <div
                  className="col-md-6 col-lg-4 filtr-item"
                  data-category="web"
                  data-sort="value"
                >
                  <div className="gallery-photo">
                    <img
                      src="\assets\img\gal23.webp"
                      alt="viserfly"
                      className="gallery-photo__img"
                    />
                    <div className="gallery-photo__content">
                      <ul className="list list--row">
                        <li className="list--row__item">
                          <a
                            href="\assets\img\gal23.webp"
                            className="t-link gallery-photo-magnify gallery-photo__icon btn btn--sqr"
                          >
                            <i className="bx bx-show" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-6 col-lg-4 filtr-item"
                  data-category="branding"
                  data-sort="value"
                >
                  <div className="gallery-photo">
                    <img
                      src="\assets\img\gal24.webp"
                      alt="viserfly"
                      className="gallery-photo__img"
                    />
                    <div className="gallery-photo__content">
                      <ul className="list list--row">
                        <li className="list--row__item">
                          <a
                            href="\assets\img\gal24.webp"
                            className="t-link gallery-photo-magnify gallery-photo__icon btn btn--sqr"
                          >
                            <i className="bx bx-show" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-6 col-lg-4 filtr-item"
                  data-category="web"
                  data-sort="value"
                >
                  <div className="gallery-photo">
                    <img
                      src="\assets\img\gal28.webp"
                      alt="viserfly"
                      className="gallery-photo__img"
                    />
                    <div className="gallery-photo__content">
                      <ul className="list list--row">
                        <li className="list--row__item">
                          <a
                            href="\assets\img\gal28.webp"
                            className="t-link gallery-photo-magnify gallery-photo__icon btn btn--sqr"
                          >
                            <i className="bx bx-show" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-6 col-lg-4 filtr-item"
                  data-category="branding"
                  data-sort="value"
                >
                  <div className="gallery-photo">
                    <img
                      src="\assets\img\gal25.webp"
                      alt="viserfly"
                      className="gallery-photo__img"
                    />
                    <div className="gallery-photo__content">
                      <ul className="list list--row">
                        <li className="list--row__item">
                          <a
                            href="\assets\img\gal25.webp"
                            className="t-link gallery-photo-magnify gallery-photo__icon btn btn--sqr"
                          >
                            <i className="bx bx-show" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-6 col-lg-4 filtr-item"
                  data-category="seo"
                  data-sort="value"
                >
                  <div className="gallery-photo">
                    <img
                      src="\assets\img\gal26.webp"
                      alt="viserfly"
                      className="gallery-photo__img"
                    />
                    <div className="gallery-photo__content">
                      <ul className="list list--row">
                        <li className="list--row__item">
                          <a
                            href="\assets\img\gal26.webp"
                            className="t-link gallery-photo-magnify gallery-photo__icon btn btn--sqr"
                          >
                            <i className="bx bx-show" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-6 col-lg-4 filtr-item"
                  data-category="branding"
                  data-sort="value"
                >
                  <div className="gallery-photo">
                    <img
                      src="\assets\img\gal27.webp"
                      alt="viserfly"
                      className="gallery-photo__img img-fluid"
                    />
                    <div className="gallery-photo__content">
                      <ul className="list list--row">
                        <li className="list--row__item">
                          <a
                            href="\assets\img\gal27.webp"
                            className="t-link gallery-photo-magnify gallery-photo__icon btn btn--sqr"
                          >
                            <i className="bx bx-show" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <div className="col-12">
              <div className="row filter-container g-0">
                <div
                  className="col-md-6 col-lg-4 filtr-item"
                  data-category="web"
                  data-sort="value"
                >
                  <div className="gallery-photo">
                    <img
                      src="\assets\img\gal29.webp"
                      alt="viserfly"
                      className="gallery-photo__img"
                    />
                    <div className="gallery-photo__content">
                      <ul className="list list--row">
                        <li className="list--row__item">
                          <a
                            href="\assets\img\gal29.webp"
                            className="t-link gallery-photo-magnify gallery-photo__icon btn btn--sqr"
                          >
                            <i className="bx bx-show" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-6 col-lg-4 filtr-item"
                  data-category="branding"
                  data-sort="value"
                >
                  <div className="gallery-photo">
                    <img
                      src="\assets\img\gal30.webp"
                      alt="viserfly"
                      className="gallery-photo__img"
                    />
                    <div className="gallery-photo__content">
                      <ul className="list list--row">
                        <li className="list--row__item">
                          <a
                            href="\assets\img\gal30.webp"
                            className="t-link gallery-photo-magnify gallery-photo__icon btn btn--sqr"
                          >
                            <i className="bx bx-show" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-6 col-lg-4 filtr-item"
                  data-category="web"
                  data-sort="value"
                >
                  <div className="gallery-photo">
                    <img
                      src="\assets\img\gal31.webp"
                      alt="viserfly"
                      className="gallery-photo__img"
                    />
                    <div className="gallery-photo__content">
                      <ul className="list list--row">
                        <li className="list--row__item">
                          <a
                            href="\assets\img\gal31.webp"
                            className="t-link gallery-photo-magnify gallery-photo__icon btn btn--sqr"
                          >
                            <i className="bx bx-show" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-6 col-lg-4 filtr-item"
                  data-category="branding"
                  data-sort="value"
                >
                  <div className="gallery-photo">
                    <img
                      src="\assets\img\gal32.webp"
                      alt="viserfly"
                      className="gallery-photo__img"
                    />
                    <div className="gallery-photo__content">
                      <ul className="list list--row">
                        <li className="list--row__item">
                          <a
                            href="\assets\img\gal32.webp"
                            className="t-link gallery-photo-magnify gallery-photo__icon btn btn--sqr"
                          >
                            <i className="bx bx-show" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-6 col-lg-4 filtr-item"
                  data-category="seo"
                  data-sort="value"
                >
                  <div className="gallery-photo">
                    <img
                      src="\assets\img\gal33.webp"
                      alt="viserfly"
                      className="gallery-photo__img"
                    />
                    <div className="gallery-photo__content">
                      <ul className="list list--row">
                        <li className="list--row__item">
                          <a
                            href="\assets\img\gal33.webp"
                            className="t-link gallery-photo-magnify gallery-photo__icon btn btn--sqr"
                          >
                            <i className="bx bx-show" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-6 col-lg-4 filtr-item"
                  data-category="branding"
                  data-sort="value"
                >
                  <div className="gallery-photo">
                    <img
                      src="\assets\img\gal34.webp"
                      alt="viserfly"
                      className="gallery-photo__img img-fluid"
                    />
                    <div className="gallery-photo__content">
                      <ul className="list list--row">
                        <li className="list--row__item">
                          <a
                            href="\assets\img\gal34.webp"
                            className="t-link gallery-photo-magnify gallery-photo__icon btn btn--sqr"
                          >
                            <i className="bx bx-show" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <div className="col-12">
              <div className="row filter-container g-0">
                <div
                  className="col-md-6 col-lg-4 filtr-item"
                  data-category="web"
                  data-sort="value"
                >
                  <div className="gallery-photo">
                    <img
                      src="\assets\img\gal35.webp"
                      alt="viserfly"
                      className="gallery-photo__img"
                    />
                    <div className="gallery-photo__content">
                      <ul className="list list--row">
                        <li className="list--row__item">
                          <a
                            href="\assets\img\gal35.webp"
                            className="t-link gallery-photo-magnify gallery-photo__icon btn btn--sqr"
                          >
                            <i className="bx bx-show" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-6 col-lg-4 filtr-item"
                  data-category="branding"
                  data-sort="value"
                >
                  <div className="gallery-photo">
                    <img
                      src="\assets\img\gal36.webp"
                      alt="viserfly"
                      className="gallery-photo__img"
                    />
                    <div className="gallery-photo__content">
                      <ul className="list list--row">
                        <li className="list--row__item">
                          <a
                            href="\assets\img\gal36.webp"
                            className="t-link gallery-photo-magnify gallery-photo__icon btn btn--sqr"
                          >
                            <i className="bx bx-show" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-6 col-lg-4 filtr-item"
                  data-category="web"
                  data-sort="value"
                >
                  <div className="gallery-photo">
                    <img
                      src="\assets\img\gal37.webp"
                      alt="viserfly"
                      className="gallery-photo__img"
                    />
                    <div className="gallery-photo__content">
                      <ul className="list list--row">
                        <li className="list--row__item">
                          <a
                            href="\assets\img\gal37.webp"
                            className="t-link gallery-photo-magnify gallery-photo__icon btn btn--sqr"
                          >
                            <i className="bx bx-show" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-6 col-lg-4 filtr-item"
                  data-category="branding"
                  data-sort="value"
                >
                  <div className="gallery-photo">
                    <img
                      src="\assets\img\gal38.webp"
                      alt="viserfly"
                      className="gallery-photo__img"
                    />
                    <div className="gallery-photo__content">
                      <ul className="list list--row">
                        <li className="list--row__item">
                          <a
                            href="\assets\img\gal38.webp"
                            className="t-link gallery-photo-magnify gallery-photo__icon btn btn--sqr"
                          >
                            <i className="bx bx-show" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-6 col-lg-4 filtr-item"
                  data-category="seo"
                  data-sort="value"
                >
                  <div className="gallery-photo">
                    <img
                      src="\assets\img\gal39.webp"
                      alt="viserfly"
                      className="gallery-photo__img"
                    />
                    <div className="gallery-photo__content">
                      <ul className="list list--row">
                        <li className="list--row__item">
                          <a
                            href="\assets\img\gal39.webp"
                            className="t-link gallery-photo-magnify gallery-photo__icon btn btn--sqr"
                          >
                            <i className="bx bx-show" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-6 col-lg-4 filtr-item"
                  data-category="branding"
                  data-sort="value"
                >
                  <div className="gallery-photo">
                    <img
                      src="\assets\img\gal40.webp"
                      alt="viserfly"
                      className="gallery-photo__img img-fluid"
                    />
                    <div className="gallery-photo__content">
                      <ul className="list list--row">
                        <li className="list--row__item">
                          <a
                            href="\assets\img\gal40.webp"
                            className="t-link gallery-photo-magnify gallery-photo__icon btn btn--sqr"
                          >
                            <i className="bx bx-show" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* New Gallery End */}

      {/* CTA  */}
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="cta bg--dark">
              <div className="row g-3 align-items-lg-center">
                <div className="col-12 col-lg-4 col-xl-3">
                  <div className="footer-logo text-center">
                    <img
                      src="assets/img/logon.png"
                      alt="viserfly"
                      className="img-fluid"
                    />
                  </div>
                </div>
                <div className="col-12 col-lg-8 col-xl-9">
                  <ul
                    className="
              list list--row
              justify-content-center justify-content-md-between
              align-items-md-center
            "
                  >
                    <li className="list--row__item">
                      <h3
                        className="
                  text-capitalize
                  t-text-white
                  mt-0
                  text-center text-md-start
                  ps-xl-3
                "
                      >
                        Like to become world class aviation professionals join alroz aviation institute
                      </h3>
                      <p className="t-text-white text-center text-md-start ps-xl-3">
                        Alroz Aviation Institute
                      </p>
                    </li>
                    <li className="list--row__item">
                      <a
                        href="become-pilot.html"
                        className="t-link btn btn--light btn--lg text-capitalize"
                      >
                        become an pilot
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* CTA End */}
      <Footer />
    </>
  )
}

export default Gallery